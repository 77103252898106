import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Helske() {

  const data = useStaticQuery(graphql`{
  helske: file(relativePath: {eq: "helske2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />
      <GatsbyImage
        image={data.helske.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Digitálne služby" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Celý digital pre Helske a jej dcérske spoločnosti</h1>
              <p>Helske je spoločnosť s rôznymi divíziami. Vyrábajú moderné stavebné materiály v Nemecku a na Slovensku. Ich divízia s názvom Helske People Care kombinuje nadštandardné bývanie so zariadeniami starostlivosti o seniorov a službami profesionálnej starostlivosti
<br></br><br></br>
Našou úlohou bolo vytvoriť všetky „zastrešujúce“ digitálne potreby, ktoré pokrývajú všetko od webových stránok pre všetky divízie vo všetkých krajinách, ako aj vytváranie tlačových materiálov, beh reklamných kampaní a vývoj softvéru na zákazku.</p>
            </div>
            
          </div>
          
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/piqipi" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
